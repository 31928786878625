import { PageProps } from "gatsby";
import React from "react";

import { Newsletter } from "../components/pages/Newsletter";

import { snProps } from "../js/utils";

const NewsletterPage = (props: PageProps) => (
  <Newsletter {...{ ...snProps, ...props }} />
);

export default NewsletterPage;
